<template>
  <div class="main r-back-grey">
    <div class="top-wrap top-wrap-back recuit-top-back">
      <div class="top-mask"></div>
      <div class="top-box">
        <div class="top-box-body">
          <h3 class="top-box-title">神仙网络邀您一起----位列仙班</h3>
          <div class="top-box-desc">
            <p>约你当老板干大事，来不来? 辉煌腾达光宗耀祖不是梦</p>
          </div>
          <!-- <div class="top-box-png">
            <img src="../assets/招聘.svg" alt="" />
          </div> -->
        </div>
      </div>
    </div>

    <div class="message-wrap">
      <div class="message-box">
        <div class="message-header">
          <h3>
            <span class="header-icon"
              ><img src="../assets/arrow-left.png" width="30" alt=""
            /></span>
            选择神仙网络
          </h3>
        </div>
        <div class="message-center">
          <div class="message-center-wrap">
            <ul class="center-list">
              <li class="center-item">
                <div class="center-icon">64*64</div>
                <div class="center-text">
                  <div class="center-title">智慧连接，美好生活</div>
                  <div class="center-underline-blue"></div>
                  <div class="center-textp">
                    腾讯长期致力于社交平台与数字内容两大核心业务，积极拥抱产业互联网，努力成为各行各业数字化助手，通过连接，提升每一个人的生活品质。
                  </div>
                </div>
              </li>
              <li class="center-item">
                <div class="center-icon">64*64</div>
                <div class="center-text">
                  <div class="center-title">智慧连接，美好生活</div>
                  <div class="center-underline-green"></div>
                  <div class="center-textp">
                    腾讯长期致力于社交平台与数字内容两大核心业务，积极拥抱产业互联网，努力成为各行各业数字化助手，通过连接，提升每一个人的生活品质。
                  </div>
                </div>
              </li>
              <li class="center-item">
                <div class="center-icon">64*64</div>
                <div class="center-text">
                  <div class="center-title">智慧连接，美好生活</div>
                  <div class="center-underline-orange"></div>
                  <div class="center-textp">
                    腾讯长期致力于社交平台与数字内容两大核心业务，积极拥抱产业互联网，努力成为各行各业数字化助手，通过连接，提升每一个人的生活品质。
                  </div>
                </div>
              </li>
              <li class="center-item">
                <div class="center-icon">64*64</div>
                <div class="center-text">
                  <div class="center-title">智慧连接，美好生活</div>
                  <div class="center-underline-red"></div>
                  <div class="center-textp">
                    腾讯长期致力于社交平台与数字内容两大核心业务，积极拥抱产业互联网，努力成为各行各业数字化助手，通过连接，提升每一个人的生活品质。
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="message-wrap r-back-grey">
      <div class="message-box">
        <div class="message-header">
          <h3>
            <span class="header-icon"
              ><img src="../assets/arrow-left.png" width="30" alt=""
            /></span>
            选择你的职位
          </h3>
        </div>
        <div class="message-center">
          <div class="message-center-wrap">
            <ul class="center-list2">
              <li class="job-item" v-for="item,index in jobsData" v-bind:key="index">
                <div href="#" class="job-item-link">
                  <router-link to="/recruit" class="job-item-routelink">
                    <p class="job-item-title">{{item.jobName}}</p>
                    <p class="job-item-tips">
                      <span>{{item.address}}</span> | <span>{{item.jobType}}</span> |
                      <span>{{item.time}}</span>
                    </p>
                    <p class="job-item-tips">人事邮箱:1173533427@qq.com |</p>
                    <p class="job-item-text">
                      {{item.jobDesc}}
                    </p>
                  </router-link>
                </div>
              </li>

              
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="message-wrap r-back-grey">
      <div class="message-box">
        <div class="message-header">
          <h3>
            <span class="header-icon"
              ><img src="../assets/arrow-left.png" width="30" alt=""
            /></span>
            员工相册
          </h3>
        </div>
        <div class="message-center">
          <div class="message-center-wrap">
            <div class="center">
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: "recruit",
  data() {
    return {
      imgs:[{'id':1,'url':'https://www-static.qbox.me/_next/static/media/shenzhen.dfca95a1091227f87f47e57ddcc458a4.png'},
      {'id':2,'url':'https://www-static.qbox.me/_next/static/media/shenzhen.dfca95a1091227f87f47e57ddcc458a4.png'},
      {'id':3,'url':'https://www-static.qbox.me/_next/static/media/shenzhen.dfca95a1091227f87f47e57ddcc458a4.png'}],

      jobsData:require("../../static/json/recruit/jobsData.json")
    };
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/public.css");

.recuit-top-back{
  background-image: url('../assets/recruit.jpg');
  background-size: 100%;
  background-position-y: -200px;
}

.r-back-grey {
  background: #f3f5f8;
}

.message-wrap {
  position: relative;
}
.message-box {
  margin: 0 auto;
  width: 1200px;
}
.message-header {
  padding: 56px 0 56px;
}
.header-icon {
}
.message-header h3 {
  display: inline-block;
  position: relative;
  font-size: 32px;
  color: #323232;
  line-height: 32px;
  padding-left: 18px;
}
.message-center {
}
.center-item {
  width: 21.6%;
  margin: 0 20px 0;

  display: inline-block;
}
/* .center-item:last-child {
  margin-right: 0;
} */
.center-icon {
  width: 64px;
  height: 64px;
}
.center-title {
  font-size: 22px;
  color: #323232;
  line-height: 32px;
  margin-bottom: 16px;
  font-weight: 600;
}
.center-underline-blue {
  height: 2px;
  background: #00a9ce;
  margin-bottom: 16px;
}

.center-underline-green {
  height: 2px;
  background: #67c23a;
  margin-bottom: 16px;
}
.center-underline-orange {
  height: 2px;
  background: #e6a23c;
  margin-bottom: 16px;
}
.center-underline-red {
  height: 2px;
  background: #f56c6c;
  margin-bottom: 16px;
}

.center-textp {
  font-size: 14px;
  color: #666666;
  line-height: 24px;
}
.job-item {
  position: relative;
  height: 204px;
  width: 530px;
  padding: 15px 15px 15px;
  background-color: #fff;
  display: inline-block;
  margin: 0 20px 30px;
  transition: all 0.35s cubic-bezier(0.2, 0, 0.2, 1);
}
.job-item:hover {
  transition: all 0.35s cubic-bezier(0.2, 0, 0.2, 1);
  /* 相对原位置平移，仍占有原有位置 */
  transform: translate(0px, -5px);
}
.job-item:hover .job-item-title {
  color: #00a9ce;
}
.job-item-link {
  width: 100%;
  height: 128px;
  display: inline-block;
  padding: 0 0 0 0;
}
.job-item-routelink {
  position: relative;
}
.job-item-title {
  font-size: 22px;
  line-height: 32px;
}
.job-item-tips {
  font-size: 16px;
  margin: 6px 0px 12px;
  line-height: 22px;
}
.job-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #808080;
  line-height: 24px;
}
</style>
